
import {
  ref, reactive, defineComponent, defineAsyncComponent,
} from 'vue';

interface Settings {
  darkmodeOn: boolean;
}

export default defineComponent({
  name: 'App',

  components: {
    TopMenu: defineAsyncComponent(
      () => import('./components/common/PageTopMenu.vue'),
    ),
  },

  setup(/* props, context */) {
    const state = reactive({
      darkmodeOn: false,
    } as Settings);

    const backgroundRef = ref<HTMLElement>();

    return { backgroundRef, state };
  },
});
