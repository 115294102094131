import {
  createRouter, createWebHistory,
} from 'vue-router';

import type { RouteRecordRaw } from 'vue-router';

/* Can use in component as directives: */
/* webpackPrefetch: true */
/* webpackChunkName: "home-chunk" */
/* webpackMode: "lazy" */

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/ViewHome.vue'),
    meta: {
      gtm: 'Home',
    },
  },

  {
    path: '/projects',
    name: 'Projects',
    component: () => import('@/components/common/PageProjects.vue'),
    meta: {
      gtm: 'Projects',
    },
  },

  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/ViewAbout.vue'),
    meta: {
      gtm: 'About',
    },
  },

  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/components/common/PageContact.vue'),
    meta: {
      gtm: 'Contact',
    },
  },

  /* Project pages */

  {
    path: '/projects/:id',
    name: 'project-template',
    component: () => import('@/components/projects/PageProjectTemplate.vue'),
    meta: {
      gtm: 'project-template',
    },
  },

  /* Live Views */

  {
    path: '/music',
    name: 'Music',
    component: () => import('@/components/music/VSPlayer.vue'),
    meta: {
      gtm: 'Music',
    },
  },

  /* Redirects */
  { path: '/:pathMatch(.*)*', redirect: { name: 'Home' } },

  /* Work in progress */

  // {
  //   path: '/other',
  //   name: 'Other',
  //   component: () =>
  //   import(/* webpackChunkName: "dev-other-chunk" */ '@/components/common/PageOther.vue'),
  //   meta: {
  //     gtm: 'dev-other'
  //   }
  // },

  // {
  //   path: '/rtc',
  //   name: 'Rtc',
  //   component: () =>
  //   import(/* webpackChunkName: "dev-rtc-chunk" */ '@/components/other/RtcClient.vue'),
  //   meta: {
  //     gtm: 'dev-rtc'
  //   }
  // },

  // {
  //   path: '/video',
  //   name: 'Video Player',
  //   component: () =>
  //   import(/* webpackChunkName: "dev-video-chunk" */ '@/components/other/VideoPlayer.vue'),
  //   meta: {
  //     gtm: 'dev-video'
  //   }
  // },

  // {
  //   path: '/login',
  //   name: 'Loginpage',
  //   component: () =>
  //   import(/* webpackChunkName: "dev-login-chunk" */ '@/components/common/PageLogin.vue'),
  //   meta: {
  //     gtm: 'dev-login'
  //   }
  // }
];

const router = createRouter({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // https://stackoverflow.com/questions/54535838/scroll-behaviour-vuejs-not-working-properly
    document.getElementById('app')?.scrollIntoView();
    // document.getElementById('app')?.scrollTo(0, 0)
    // window?.scrollTo(0, 0)

    // always scroll to top
    return {
      top: 0,
    };
  },

  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // document.title = to.meta.title as string;
  next(); // Must call next
});

export default router;
