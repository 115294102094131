interface Image {
  imgPath: string;
  alt: string;
}

interface Video {
  vidPath: string;
  alt: string;
}

interface LiveLink {
  path: string;
  external: boolean;
}

interface Project {
  internalId: number;
  hexId: string;
  liveLink: LiveLink;

  titleFirst: string;
  titleSecond?: string;

  tags: string[];
  images: Image[];
  vidoes?: Video[];

  aboutParagraphs: string[];
  whyParagraphs: string[];
}

interface Settings {
  projects: Project[];
}

export const projectStore = {
  state: () => ({
    projects: [
      {
        internalId: 1,
        hexId: '0x00001',

        titleFirst: 'Atmospheric Scattering',
        titleSecond: '',

        tags: ['c++', 'opengl', 'glsl', 'ray tracing', 'game engine'],
        images: [
          {
            imgPath: '/img/proj/engine/atmo/atmo-16x9-medium.webp',
            alt: 'Figure 1. Solar eclipse as seen from space.',
          },
          {
            imgPath: '/img/proj/engine/atmo/atmo-16x9-medium2.webp',
            alt: 'Figure 2. Sunlight hitting a planet as seen from space.',
          },
          {
            imgPath: '/img/proj/engine/atmo/atmo-16x9-medium3.webp',
            alt: 'Figure 3. Total solar eclipse around the planet.',
          },
        ],
        videos: [
          {
            vidPath: 'https://www.youtube.com/embed/JAG8D7fsILI',
            alt: 'real-time rendering of the implentation, views both from sapce and earth.',
          },
        ],

        aboutParagraphs: [
          'Atmospheric scattering is the result of light entering the atmosphere '
          + 'and interacting with particles in the air. It is due to this we are '
          + 'able experience lovely sunsets and blue skies. '
          + 'This is my simulation of that phenomena, based on Sean O\'Neals implementation '
          + '(NVIDIA Gpu Gems 2).',

          'We have a planet which has a atmosphere around it, a sun which we will trace rays towards, '
          + 'and a camera from which we will trace rays from. '
          + ' For each pixel we trace a ray from the camera, if the rays go through the '
          + 'atmosphere we need to account for the scattering which involves a double integral. '
          + 'Since there is no analytic solution to the scattering equation it '
          + 'has to be solved numerically, this by tracing the rays from the camera and for each '
          + 'step taken also trace rays back towards the camera and towards the sun. '
          + 'Closer to the planets surface there is more Mie scattering, which is the '
          + 'phenomena when light interacts with bigger particles, like pollution or water. At higher '
          + 'altitues where there are less particles Rayleigh scattering is used.',

          'Althought it is possible to get stunning views in real-time using this method it '
          + 'also has downsides, it is difficult to control the outcome of the result.',
        ],

        whyParagraphs: [
          'Atmospheric scattering can greatly enhances the look and feel of a environment, '
          + 'simply flying around in those can be enjoyable. '
          + 'I read up more about the physical phenomena to get '
          + 'some background, and a better understanding. '
          + 'Reading through NVIDA Gpu Gems, I found Sean O\'Neals real-time implementation '
          + 'of atmosperic scattering and I decided attempt an implementation based on that. '
          + 'The implementation is done using my engine.',
        ],
      },
      {
        internalId: 2,
        hexId: '0x00002',

        titleFirst: 'SSAO',
        titleSecond: '',

        tags: ['c++', 'opengl', 'glsl', 'game engine'],
        images: [
          {
            imgPath: '/img/proj/engine/ssao/ssao-bg-trans-16x9-medium.webp',
            alt: 'Figure 1. Screen Spaced Ambient Occlusion.',
          },
          {
            imgPath: '/img/proj/engine/ssao/ssao-16x9-medium2.webp',
            alt: 'Figure 2. 300 samples per fragment, together with phong shading.',
          },
          {
            imgPath: '/img/proj/engine/ssao/ssao-16x9-medium3.webp',
            alt: 'Figure 3. 300 samples per fragment, together with phong shading.',
          },
          {
            imgPath: '/img/proj/engine/ssao/ssao-16x9-medium4.webp',
            alt: 'Figure 4. You can get rid of high frequency noises using a Gaussian filter, '
              + 'the right side is rendered with bidirectional 5x1 Gaussian filter.',
          },
          {
            imgPath: '/img/proj/engine/ssao/ssao-16x9-medium5.webp',
            alt: 'Figure 5. Renderings with different amount of samples, '
              + 'from the left 10, 20, 40 and 100 samples.',
          },
        ],

        aboutParagraphs: [
          'SSAO (Screen Spaced Ambient Occlusion) is a technique '
          + 'used to approximate the ambient occlusion in the scene.',

          'A sample kernel with uniformly distributed values inside '
          + 'a hemisphere is generated on the CPU side, which serves '
          + ' as input into a shader to sample depths, around each fragment.',

          'To be able to calculate an estimate of the amibent occlusion, '
          + 'the depth of the scene at each sample point is reconstructed. '
          + 'The scene depth at the sample point is compared with the '
          + 'depth of the sample point, if the depth of the sample point is '
          + 'farther away than the actual scene depth, then we add to the occlusion '
          + ' for that fragment.',

          'This effect greatly enhance the lighting, and can '
          + 'be used in real-time. A good amount of tweaks and optimizations '
          + ' are possible, depening on what outcome you are going for.',
        ],

        whyParagraphs: [
          'The technique is pretty much standard in modern '
          + 'games, and can give a great visual improvement. '
          + 'It has been around since 2007, since first time use '
          + 'in Cry Engine. '
          + 'So I wanted to implement it myself also, as a part '
          + 'of a engine I am working on.',
        ],
      },
      {
        internalId: 3,
        hexId: '0x00003',
        liveLink: {
          path: '/music',
          external: false,
        },

        titleFirst: 'Audio Visualizer',
        titleSecond: '',

        tags: ['typescript', 'webgl', 'html5', 'css', 'vue', 'three.js', 'web audio api'],
        images: [
          {
            imgPath: '/img/proj/web-audio-visualizer/audiovis-16x9-medium.webp',
            alt: 'Figure 1. Screenshot of the audio visualization.',
          },
          {
            imgPath: '/img/proj/web-audio-visualizer/audiovis2-16x9-medium.webp',
            alt: 'Figure 2. The audio visualizer together with custom audio controllers.',
          },
        ],

        aboutParagraphs: [
          'This is a web music visualizer, which in real-time synchronizes graphics with sound',

          'Web Audio API is utilized for handling the sound '
          + 'and the analysis (FFT) of the sound. The frequency data '
          + 'from the analysis is used to additionally calculate avarage '
          + 'ang fractural values used in the visualization, '
          + 'the WebGL based library Three.js is used for the visualization.',

          'By default it connects to a online radio stream, '
          + 'but it is also possible to open your own locally '
          + 'stored sound files.',

          'You can use your mouse and scroll wheel to interact with live view.',
        ],

        whyParagraphs: [
          'I always liked music visualizers, especially those that could '
          + 'be found in the classic Winamp mp3-players. So I figured it '
          + 'would be a fun challange to try make my own, using web technology.',
        ],
      },
      {
        internalId: 4,
        hexId: '0x00004',

        titleFirst: 'Voxel Based System',
        titleSecond: '',

        tags: ['c++', 'opengl', 'glsl', 'geometry instancing', 'game engine'],
        images: [
          {
            imgPath: '/img/proj/engine/voxel/voxel-16x9-medium.webp',
            alt: 'Figure 1. Many chunks of voxels (spacing inbetween to better see what is going on).',
          },
          {
            imgPath: '/img/proj/engine/voxel/voxel-16x9-medium2.webp',
            alt: 'Figure 2. One chunk of voxels.',
          },
          {
            imgPath: '/img/proj/engine/voxel/voxel-16x9-medium3.webp',
            alt: 'Figure 3. When voxels are being removed the chunk updates '
              + 'and only renders what now is possible to see.',
          },
          {
            imgPath: '/img/proj/engine/voxel/voxel-16x9-medium4.webp',
            alt: 'Figure 4. With less spacing between voxels.',
          },
        ],

        aboutParagraphs: [
          'Voxels are like 3D pixels, they only have a position and size. '
          + 'Apart from the visual look of using voxels the computational '
          + 'cost of working with them are low, which saves us time for '
          + 'other effetcs.',

          'The system contains of a chunk-manager which holds and manages '
          + 'chunks and each chunk in turn hold and manages the invidual voxels. '
          + 'Not every voxel is always visible, many of the voxels will be '
          + 'occluded by other voxels which we do do not need to render.',

          'Since we know the size of the voxels and the positions of each chunk '
          + 'it is possible to store the voxels in linear structure, '
          + 'and thereby with a low cost access neighboring voxels. '
          + 'By accessing neighbouring voxels we can figure out which voxels '
          + 'are occluded, and not in need of rendering.',

          'All voxels have the same geometry so the same object is used in '
          + 'the rendering for each voxel (Geometry Instancing), only a position '
          + 'offset is sent along each voxel.',

          'It is possible to pick a voxel to mark it as either visible or hidden '
          + 'doing this marks the parenting chunk as in need of an update. '
          + 'Next frame the voxels in chunks in need of updates are traversed through, '
          + ' in order to find out what now is visible and not.',
        ],

        whyParagraphs: [
          'I was curiose about voxels, so many games are using voxels, '
          + 'specially one of the bigger ones, Minecraft. Also I have '
          + 'plans to utilize this futher in my engine, in which this '
          + 'is implemented.',
        ],
      },
      {
        internalId: 5,
        hexId: '0x00005',

        titleFirst: 'Choropleth Map',
        titleSecond: '',

        tags: ['html5', 'css', 'vue', 'vuex', 'typescript', 'node', 'information visualization'],
        images: [
          {
            imgPath: '/img/proj/web-infovis/infovis-16x9-medium.webp',
            alt: 'Figure 1. In this visualization warmer colors more deaths, and colder less. Black means there was no available data. '
              + 'The circle-areas are mapped to total deaths, as the colors of each country.',
          },
          {
            imgPath: '/img/proj/web-infovis/infovis-16x9-medium2.webp',
            alt: 'Figure 2. Europe, 30th of March, 2020.',
          },
          {
            imgPath: '/img/proj/web-infovis/infovis-16x9-medium3.webp',
            alt: 'Figure 2. 6th of April, 2020, Europe got more red.',
          },
        ],

        aboutParagraphs: [
          'This is a map that visualize geographically bound data, '
          + 'in this covid-19 data bound to countries have been used. '
          + 'Data is presented per day and the day can be picked using a '
          + 'slider.',

          'It is a good idea to have some data to actually work with when '
          + 'developing a visulization, I happend to chooce a dataset '
          + 'containg Covid-19 data, amount of deaths per country. ',

          'Not all countries reports the data in the same way (not all even report), '
          + 'it was difficult to find a good dataset to work with. '
          + 'Eventually i found a reliable git-repository where someone did collect the data '
          + 'regulary and stored it in somewhat uniform way.',

          'A backend was written with Node.js that parsed the data and collected the '
          + 'parts of interest. Also the ID of countries had to be matched with the '
          + 'ID of the polygons on the map, in order to us to keep track of where to bind '
          + 'the data. The processed data is saved in a geo-json format '
          + 'used by the visualization.',

          'Leaflet has been used for the map, together with d3 to easier handle the data '
          + 'and colors.',

          'In the current sate of the visualization we do only get indications of what is '
          + 'going on by looking at the colors and circle-areas. As for futhers work it would '
          + 'be good to work more with the UI, add possibility to change colors, ranges etc. '
          + 'This together with tables, graphs and other visuauzalition, to be able to look at '
          + 'the data in different ways.',
        ],

        whyParagraphs: [
          'There is alot interesting data that are bound to geographical areas '
          + 'or positions, and with 5G upcomming there will be even more. To get a '
          + 'better insight and understanding we can visualizae the data, using maps for '
          + 'that purpose is convenient.',
        ],
      },
      {
        internalId: 6,
        hexId: '0x00006',

        titleFirst: 'Procedural Terrain',
        titleSecond: '',

        tags: ['c++', 'opengl', 'glsl', 'procedural methods', 'game engine'],
        images: [
          {
            imgPath: '/img/proj/engine/terrain/terrain-16x9-medium2.webp',
            alt: 'Figure 1. Colored terrain based on height, with some noise for '
              + 'a more natural variation.',
          },
          {
            imgPath: '/img/proj/engine/terrain/terrain-16x9-medium.webp',
            alt: 'Figure 2. A patch of terrain.',
          },
          {
            imgPath: '/img/proj/engine/terrain/terrain-16x9-medium3.webp',
            alt: 'Figure 3. Higher frequency terrain, together with SSAO and phong shading.',
          },
          {
            imgPath: '/img/proj/engine/terrain/terrain-16x9-medium4.webp',
            alt: 'Figure 4. Lower frequency in the terrain.',
          },
          {
            imgPath: '/img/proj/engine/terrain/terrain-16x9-medium5.webp',
            alt: 'Figure 5. Another view.',
          },
          {
            imgPath: '/img/proj/engine/terrain/terrain-16x9-medium6.webp',
            alt: 'Figure 6. Wireframe computed in geometry-shader and drawn '
              + 'on top of the terrain in screenspace.',
          },
        ],

        aboutParagraphs: [
          'Implementation of a terrain generator which user procedural '
          + 'methods and fractals in the generation.',

          'First a grid of specified desired size is created, sereral octaves of '
          + 'perlin noise is applied to the height components to obtain a more '
          + 'natural look with self simularity (fractals). Since the layout of '
          + 'the grid is known we can access the neighbours without using the '
          + ' half edge mesh data structure and compute the normals for each '
          + ' of the vertex points.',

          'The resolution of the grid, frequency, offsets, period and amplitude '
          + 'can easily be changed to get a different outcome of the generated '
          + ' terrain.',
        ],

        whyParagraphs: [
          'Having an outdoor environment is useful for many cases. By generating '
          + 'the terrain with procedural methods we easily control the outcome of the terrain, '
          + 'also we dont have to store it on the disk, and we can easily generate '
          + 'more on demand. Implementation is done using my engine.',
        ],
      },
      {
        internalId: 7,
        hexId: '0x00007',

        titleFirst: 'Optical Music Recognition',
        titleSecond: '',

        tags: ['matlab', 'image processing', 'image analysis'],
        images: [
          {
            imgPath: '/img/proj/note-detection/note-16x9-medium.webp',
            alt: 'Figure 1. Example of input used in the optical music recognition.',
          },
          {
            imgPath: '/img/proj/note-detection/note-16x9-medium2.webp',
            alt: 'Figure 2. Staff lines and their vertical spacing are detected by counting lines and spacings in each column, '
              + ' the most occuring spacing and line height is most likely the stafflines and spacing between them.',
          },
          {
            imgPath: '/img/proj/note-detection/note-16x9-medium3.webp',
            alt: 'Figure 3. Lines are detected and postions are identified in a test image.',
          },
          {
            imgPath: '/img/proj/note-detection/note-16x9-medium4.webp',
            alt: 'Figure 4. When we have the stafflines are their spacing we identify the position of all note heads.',
          },
          {
            imgPath: '/img/proj/note-detection/note-16x9-medium5.webp',
            alt: 'Figure 5. For this test image all notes are detected in which '
              + '157 of a total 174 are identified correctly',
          },
          {
            imgPath: '/img/proj/note-detection/note-16x9-medium6.webp',
            alt: 'Figure 6. This test image detects and identifies all 92 notes correctly.',
          },
          {
            imgPath: '/img/proj/note-detection/note-16x9-medium7.webp',
            alt: 'Figure 7. In this more unclear image all 113 notes are detected, but 20 of them '
              + 'are identified with the wrong result.',
          },
        ],

        aboutParagraphs: [
          'This is a MATLAB application that uses image processing and '
          + 'image analysis methods to identify musical notes from musical scores.',

          'At first the image preprocessed, cleand up and sharpened. Ater doing so the '
          + 'position of stafflines and the spacing between them is estimated. '
          + 'Since the clefs have a distinguishable shape, matching can be done to find them. '
          + 'When the position of the clefs is obtained a more accurate position of the '
          + 'staff lines can be found.',

          'By having more information of the image, we can now do additional cleaning in the '
          + 'image (removing data that is not of interest). '
          + 'Looking at the relation between the stafflines and the different rows of '
          + 'stafflines it is now possible to do geometrical and perspective correction of the image.',

          'The stafflines are removed using a median filter and thereafter the noteheads are detected. '
          + 'By having the position of each note head we can now label all objects in the image, and '
          + 'remove each one not containing a notehead. The pitch of each note is note is determined '
          + 'by using the note heads distance to the center line in the current stave. '
          + 'This has been a collaborative project where we had to put dispose time to read up '
          + 'on papers in the field.',
        ],

        whyParagraphs: [
          'Optical music recognition (OMR) is a field with the goal '
          + 'to interpret music scores and detect the notes in it.',

          'Music has been around with us for a long time, '
          + 'this means there still is a lot of music scores out '
          + 'there that need to get digitalized so more people '
          + 'can enjoy them.',

          'To limit the work identification of musical notation symbols is limited to the the ones '
          + ' present in the figures',
        ],
      },
      {
        internalId: 8,
        hexId: '0x00008',
        liveLink: {
          path: 'https://jimli570.github.io/web-matrix-effect/index.html?matrixText=0101ABCDEF&textColor=00CC00&backgroundColor=0,0,0',
          external: true,
        },

        titleFirst: 'The Matrix',
        titleSecond: '',

        tags: ['html5', 'canvas', 'css', 'javascript'],
        images: [
          {
            imgPath: '/img/proj/web-matrix-effect/matrix-effect-16x9-medium.webp',
            alt: 'Figure 1. Screenshot of the default visualization.',
          },
          {
            imgPath: '/img/proj/web-matrix-effect/matrix-effect-16x9-medium2.webp',
            alt: 'Figure 2. The visualization with customized parameters fetched through the URI interface.',
          },
        ],

        aboutParagraphs: [
          'This is a HTML5 canvas rendering made with inspiration from The Matrix. '
          + 'The effect is also knows as Matrix digital rain.',

          'The viewspace is divided into columns, in which each column is randomly '
          + 'assigned a starting vaule somewhere in the top of the window. '
          + 'In each iteration, at every iteration a layer of background is drawn with some transparency and the '
          + 'vertical position of the columns are updated. '
          + 'Additionally in each iteration chararchters are randomized to be drawn in the columns, '
          + 'with a low possibility of getting the draw discared.',
        ],

        whyParagraphs: [
          'I wanted make something in HTML5 canvas, so I took '
          + 'inspiration from the classic The Matrix movie from 1999. '
          + 'In the movie, the character Neo gets a clear vision and is able '
          + 'too see the world in code, '
          + 'this is with inspiration from that.',
        ],
      },
      {
        internalId: 9,
        hexId: '0x00009',
        liveLink: {
          path: 'https://jimli570.github.io/web-procedural-height-map/',
          external: true,
        },

        titleFirst: 'Procedural Heightmap',
        titleSecond: '',

        tags: ['glsl', 'javascript', 'webgl', 'procedural methods', 'three.js'],
        images: [
          {
            imgPath: '/img/proj/web-procedural-height-map/map-flat-16x9-medium.webp',
            alt: 'Figure 1. Screenshot of terrain from the heightmap.',
          },
          {
            imgPath: '/img/proj/web-procedural-height-map/map-flat-16x9-medium2.webp',
            alt: 'Figure 2. Different colorscheme used.',
          },
          {
            imgPath: '/img/proj/web-procedural-height-map/map-flat-16x9-medium3.webp',
            alt: 'Figure 3. Three different renders, using different amounts of octaves in the generation. '
              + 'From left to right, 1, 2 and 8 octaves.',
          },
          {
            imgPath: '/img/proj/web-procedural-height-map/map-flat-16x9-medium4.webp',
            alt: 'Figure 4. Zoomed out view of the heightmap, with a slight crumpled effect on the map.',
          },
        ],

        aboutParagraphs: [
          'This is a webgl shader which uses procedural methods to render a heightmap.',

          'The shader utilizes multiple octaves of perlin noise to generate fractals, '
          + 'which resembles natures way of working. '
          + 'The shader mainly consists of three steps, coloring the terrain, '
          + 'drawing the height lines, and drawing the border.',

          'The height of the terrain is divided into bins, and around each bin border '
          + 'height lines are drawn, with some additonal smoothing and noise, to get '
          + 'a visually more sharming outcome.'
          + 'The height is also divided into bins, in which each one corresponds to '
          + 'a color.',

          'You can use your mouse and scroll wheel to interact with the live view, '
          + 'it is also possible to change some of the parameters in the GUI '
          + 'that will affect the outcome of the map.',
        ],

        whyParagraphs: [
          'I wanted to make a shader using procedural '
          + 'methods, so I prepared a shader with noise generation methods, '
          + 'from there I just let creativity flow and I ended making '
          + 'a heightmap.',
        ],
      },
      {
        internalId: 10,
        hexId: '0x0000A',
        liveLink: {
          path: 'https://jimli570.github.io/web-procedural-sky/clouds/index.html',
          external: true,
        },

        titleFirst: 'Procedural Sky',
        titleSecond: '',

        tags: ['glsl', 'javascript', 'webgl', 'procedural methods'],
        images: [
          {
            imgPath: '/img/proj/web-procedural-sky/procedural-sky-16x9-medium2.webp',
            alt: 'Figure 1. The sky is brighter towards zenit, where the sun '
              + 'is supposed to be. The cube is used as referencepoint.',
          },
          {
            imgPath: '/img/proj/web-procedural-sky/procedural-sky-16x9-medium.webp',
            alt: 'Figure 2. Clouds genereated by different parameters sent into the shader.',
          },
        ],

        aboutParagraphs: [
          'This is a webgl shader that with procedural methods renders the sky with '
          + 'slowly moving clouds.',

          'Perlin noise and fractals are used to generete the clouds, time serves '
          + 'as offset in the generation, which allows us to animate the clouds.'
          + 'The sky is brighter towards zenit and more blurred downwards, where '
          + 'the ocean is supposed to be.',

          'It is possible to move and look around in the live view, using the mouse '
          + 'and scroll wheel.',
        ],

        whyParagraphs: [
          'Skyboxes and skydomes are commonly used for mapping environmental texture '
          + 'maps on, which I was playing around with. While I was playing around with '
          + 'the environmental mapping I came up with the idea to make my own using '
          + 'procudural methods.',
        ],
      },
      {
        internalId: 11,
        hexId: '0x0000B',

        titleFirst: 'Geo Twitter',
        titleSecond: '',

        tags: ['html', 'css', 'php', 'twitter api', 'google maps api'],
        images: [
          {
            imgPath: '/img/proj/web-twitter-maps/twitter-map-16x9-medium.webp',
            alt: 'Figure 1. Tweets on the map at corresponding geographical location, '
              + 'from where it was tweered.',
          },
          {
            imgPath: '/img/proj/web-twitter-maps/twitter-map-16x9-medium2.webp',
            alt: 'Figure 2. The map with tweets as seen in development mode.',
          },
        ],

        aboutParagraphs: [
          'This implementation combines Twitter with Google Maps, tweets are '
          + 'shown on the map at the geographical position attached to the tweets.',

          'The implementation displays the latest ten tweets with a geographical '
          + 'location, in the area of the viewport, equests are done at even intervals.'
          + 'The requests are sent to the backend php that processes it and sends the'
          + 'request futher through Twitter API.',

          'Since this is a demo i decided to not pay for the services and was therefore '
          + 'using the google development map, and with a limited amount of request per '
          + 'minute towards twitter (limit if paying also, buy you get a few more per minute).',
        ],

        whyParagraphs: [
          'Since Twitter and Google Maps are well known, and used by many '
          + 'I decided to try out their APIs. '
          + 'Since many of the tweets have geographical information attached to them I '
          + 'decided to show the tweets on the that geographical position.'
          + '',
        ],
      },
      {
        internalId: 12,
        hexId: '0x0000C',

        titleFirst: 'Monte Carlo Ray Tracing',
        titleSecond: '',

        tags: ['c++', 'opengl', 'ray tracing'],
        images: [
          {
            imgPath: '/img/proj/monte-carlo/monte-16x9-medium.webp',
            alt: 'Figure 1. We get different results depending on the amount of samples, '
              + 'and the material of the objects.',
          },
          {
            imgPath: '/img/proj/monte-carlo/monte-16x9-medium2.webp',
            alt: 'Figure 2. A few more renderings with different materials and amount of samples.',
          },
          {
            imgPath: '/img/proj/monte-carlo/monte-16x9-medium3.webp',
            alt: 'Figure 3. Scene rendered with a strong light and refraction on the sphere.',
          },
        ],

        aboutParagraphs: [
          'This is a monte carlo ray tracer that uses monte carlo methods '
          + 'to generate rays used as samples to calculate the global illumination '
          + 'of a scene.',

          'The main aim of a global illumination algorithm is '
          + 'to solve the rendering equation and generate photo-'
          + 'realistic images.',

          'The Monte Carlo method estimates a numerical integral using '
          + 'random numbers. With this method an integral over a '
          + 'given interval is computed. '
          + 'A random value generator is defined so that the expected '
          + 'result is within the domain. '
          + 'The random valus are used to generate the rays that '
          + 'are casted and traced through the scene where the final '
          + 'pixel intensities are estimated with '
          + 'different methods. Monte Carlo ray tracing produces '
          + 'reflections and refractions together with diffuse '
          + 'surfaces with the random rays. '
          + 'The quality of the rendering can be adjusted by controlling '
          + 'the number of random values generated (samples taken).',
        ],

        whyParagraphs: [
          'In computer graphics local lighting models take into '
          + 'account only the light that comes directly from the '
          + 'light source, therefore the images that these models '
          + 'produce are not photo-realistic. Global illumination '
          + 'algorithms take into account both the light coming '
          + 'directly from the light source and the light contributions '
          + 'from other surfaces in the scene.',

          'Realistic global illumination is difficult to achive in real-time, '
          + 'but we achive that using more time and more computer power. '
          + 'Video productions with computers graphics usually have a '
          + 'ray tracer involved in the rendering.',
        ],
      },
    ],
  } as Settings),

  mutations: {
  },

  getters: {
    getAllProjects(state: Settings) {
      return state.projects;
    },

    getProject:
      // eslint-disable-next-line max-len
      (state: Settings) => (id: number) => state.projects.find((project: { internalId: number }) => project.internalId === id),
  },

  actions: {
  },

  modules: {
  },
};

export default projectStore;
