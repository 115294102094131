import { createApp } from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';

import App from './App.vue';
import router from './router';
import store from './store';

import './ts/registerServiceWorker';
import 'bootstrap/dist/js/bootstrap.min'; // Used for bootstrap mobile-menu etc.

// import './ts/initFirebase'; // If using firebase
// console.log(process.env.BASE_URL);

createApp(App).use(store).use(router)
  .use(
    createGtm({
      id: 'GTM-RYHTZP1SPP',
      defer: false,
      compatibility: false,
      nonce: '2726c7f26c',
      enabled: true,
      debug: false,
      loadScript: false,
      vueRouter: router,
      trackOnNextTick: false,
    }),
  )

  .mount('#app');
