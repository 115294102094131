import { createStore } from 'vuex';
import { projectStore } from '@/store/projects';

export default createStore({
  strict: true, // process.env.NODE_ENV !== 'production'

  modules: {
    projectStore,
  },
});
