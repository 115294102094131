import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "background",
  ref: "backgroundRef",
  class: "d-flex w-100 min-vh-100"
}
const _hoisted_2 = {
  id: "paper",
  class: "font-oswald w-100 shadow d-flex flex-column px-0 mx-0 mx-sm-2 mx-md-4 mx-lg-5 mx-xl-5 mx-xxl-5 my-0 my-sm-3 rounded-bottom"
}
const _hoisted_3 = { class: "d-flex flex-column h-100 px-2 px-sm-3" }
const _hoisted_4 = {
  id: "main-section",
  class: "d-flex flex-grow-1 mb-2 mb-md-3 mt-2 mt-md-3"
}
const _hoisted_5 = {
  id: "footer-section",
  class: "rounded-bottom py-3 px-2"
}
const _hoisted_6 = {
  href: "https://github.com/jimli570",
  target: "_blank",
  "aria-label": "Github",
  rel: "noreferrer"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  href: "https://www.linkedin.com/in/jimmy-liikala",
  target: "_blank",
  "aria-label": "Linkedin",
  rel: "noreferrer"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  href: "mailto:jimmy.liikala@gmail.com",
  "aria-label": "Email"
}
const _hoisted_11 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_menu = _resolveComponent("top-menu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_top_menu, {
          id: "top-section",
          class: "no-select position-sticky top-0"
        }),
        _createElementVNode("main", _hoisted_4, [
          _createVNode(_component_router_view)
        ])
      ]),
      _createElementVNode("footer", _hoisted_5, [
        _createElementVNode("a", _hoisted_6, [
          _createElementVNode("img", {
            src: require('@/assets/icons/github.svg'),
            class: "icon figure-img img-fluid",
            alt: "github"
          }, null, 8, _hoisted_7)
        ]),
        _createElementVNode("a", _hoisted_8, [
          _createElementVNode("img", {
            src: require('@/assets/icons/linkedin.svg'),
            class: "icon figure-img img-fluid",
            alt: "Linkedin"
          }, null, 8, _hoisted_9)
        ]),
        _createElementVNode("a", _hoisted_10, [
          _createElementVNode("img", {
            src: require('@/assets/icons/envelope.svg'),
            class: "icon figure-img img-fluid",
            alt: "Email"
          }, null, 8, _hoisted_11)
        ])
      ])
    ])
  ], 512))
}