import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions: { scope: './' },

    // eslint-disable-next-line
    ready(registration: unknown) {
      console.log('Service worker is active.');
    },

    // eslint-disable-next-line
    registered(registration: unknown) {
      console.log('Service worker has been registered.');
    },

    // eslint-disable-next-line
    cached(registration: unknown) {
      console.log('Content has been cached for offline use.');
    },

    // eslint-disable-next-line
    updatefound(registration: unknown) {
      console.log('New content is downloading.');
    },

    // eslint-disable-next-line
    updated(registration: unknown) {
      console.log('New content is available; please refresh.');
    },

    offline() {
      console

        .log('No internet connection found. App is running in offline mode.');
    },

    error(error: unknown) {
      console.error('Error during service worker registration:', error);
    },
  });
}
